import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import MoneyOutlinedIcon from '@mui/icons-material/MoneyOutlined';
import ElderlyOutlinedIcon from '@mui/icons-material/ElderlyOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import Person4OutlinedIcon from '@mui/icons-material/Person4Outlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import ArticleIcon from '@mui/icons-material/Article';
import InsightsIcon from '@mui/icons-material/Insights';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { setOpen, updateSideBarActiveTab, updateSidebar } from '../redux/executiveReducer';
import TransLogo from "../assets/KSKB_Logo_TransparentBG.PNG"
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Logout } from "@mui/icons-material";

import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import AccountBalanceSharpIcon from '@mui/icons-material/AccountBalanceSharp';
import WorkSharpIcon from '@mui/icons-material/WorkSharp';

import Mui_Tooltip from '../elements/Mui/mui_tooltip';

const drawerWidth = 280;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(6)} )`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} )`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function ExecutiveSideBar() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const active_tab = useSelector((state) => state.executive.sideBarActiveTab);
    const sidebar = useSelector((state) => state.executive.sidebar);
    const open = useSelector((state) => state.executive.open);


    // const [open, setOpen] = React.useState(true);
    const [openMR, setOpenMr] = React.useState(false);
    const [openAR, setOpenAR] = useState(false);
    const [openES, setOpenES] = useState(false);

    const pathsWithNavBar = ["/add_member","/executive_summary","/Receipts"];

    const handleDrawerOpen = () => {
        dispatch(setOpen(true));
    };

    const handleDrawerClose = () => {
        dispatch(setOpen(false));
    };

    const handleClick = (listItemName) => {
        if(listItemName === "AR"){
            setOpenAR(!openAR)
            setOpenMr(false)
            // setOpenBD(false)
            setOpenES(false)
            dispatch(updateSideBarActiveTab(''))
        }
        else if (listItemName === "MR"){
            setOpenMr(!openMR)
            setOpenAR(false)
            setOpenES(false)
            dispatch(updateSideBarActiveTab(''))

        }
        // else if(listItemName === "BD"){
        //     setOpenBD(!openBD)
        //     setOpenAR(false)
        //     setOpenES(false)
        //     dispatch(updateSideBarActiveTab(''))
        // }
        else{
            setOpenES(!openES)
            setOpenAR(false)
            setOpenMr(false)
            // setOpenBD(false)
            dispatch(updateSideBarActiveTab(''))
        }
    };
   
    const handleLogout = () => {
        localStorage.clear();
        sessionStorage.clear();
        navigate('/login');
    }; 

    const handleTabChange = (tab, section) => {
        dispatch(updateSidebar(false));
        if(!section){
            setOpenES(false)
            setOpenAR(false)
            setOpenMr(false)
        }
        switch (tab) {
            case 'CO':
                navigate('/executive_summary')
                dispatch(updateSideBarActiveTab('CO'))
                break;
            case 'RI':
                navigate('/executive_summary')
                dispatch(updateSideBarActiveTab('RI'))
                break;

            case 'NMC':
                navigate('/executive_summary')
                dispatch(updateSideBarActiveTab('NMC'))
                break;

            case 'WD':
                navigate('/executive_summary')  
                dispatch(updateSideBarActiveTab('WD'))
            
                break;
            case 'AM':
                navigate('/executive_summary') 
                dispatch(updateSideBarActiveTab('AM'))
                
                break;
            case 'MRE':
                navigate('/estate') 
                dispatch(updateSideBarActiveTab('MRE'))
                
                break;
            case 'MRB':
                navigate('/bankname') 
                dispatch(updateSideBarActiveTab('MRB'))
                
                break;
            case 'MRCB':
                navigate('/coopbank') 
                dispatch(updateSideBarActiveTab('MRCB'))
                
                break;
            case 'MROcc':
                navigate('/occupation') 
                dispatch(updateSideBarActiveTab('MROcc'))
                
                break;
            case 'AE':
                
                dispatch(updateSideBarActiveTab('AE'))
                
                break;
            case 'GDS':
                
                dispatch(updateSideBarActiveTab('GDS'))
                
                break;
            case 'NS':
                navigate('/executive_summary')
                dispatch(updateSideBarActiveTab('NS'))
                
                break;
            case 'DI':
                
                dispatch(updateSideBarActiveTab('DI'))
                
                break;
            case 'NC':
                
                dispatch(updateSideBarActiveTab('NC'))
                
                break;
            case 'ARP':
                navigate('/payments')
                dispatch(updateSideBarActiveTab('ARP'))
                
                break;
            case 'ARL':
                navigate('/loan')
                dispatch(updateSideBarActiveTab('ARL'))
                
                break;
            case 'ARSD':
                navigate('/specificDeposit')
                dispatch(updateSideBarActiveTab('ARSD'))
                
                break;
            case 'ARR':
                navigate('/Receipts')
                dispatch(updateSideBarActiveTab('ARR'))
                
                break;
            case 'ARD':
                navigate('/dividend')
                dispatch(updateSideBarActiveTab('ARD'))
                
                break;
            case 'ARDE':
                navigate('/deduction')
                dispatch(updateSideBarActiveTab('ARDE'))
                
                break;
            case 'AIR':
                navigate('/analysis')
                dispatch(updateSideBarActiveTab('AIR'))
                    
                break;
            case 'MD':
                navigate('/add_member')
                dispatch(updateSideBarActiveTab('MD'))
                break;
            case 'SC':
                navigate('/share_certificate')
                dispatch(updateSideBarActiveTab('SC'))
                break;
            // case 'AS':
            //     navigate("/account-statement")
            //     dispatch(updateSideBarActiveTab('AS'))
            //     break;
            case 'BAS':
                navigate("/account-statement")
                dispatch(updateSideBarActiveTab('BAS'))
                break;
            default:
                break;
        }
    };

  return (
    <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open} sx={{ background: 'var(--pallate-green-500, #E7EFE9)'}}>
            <Toolbar sx={{minHeight: '40px !important'}}>
                <IconButton
                    color="black"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                    marginRight: 5,
                    ...(open && { display: 'none' }),
                    }}
                >
                    <MenuIcon />
                </IconButton>
            
                <Box sx={{ background: 'var(--pallate-green-500, #E7EFE9)',width:"100%", padding: "2px 2px", display: "flex", alignItems: "center", justifyContent: "space-between",position:"sticky",top:0,zIndex:2 }}>
        
                    <Box sx={{display:"flex",gap:"24px",alignItems:"center"}}>
                        {/* <IconButton onClick={()=>dispatch(updateSidebar(true))}><MenuIcon sx={{color:"#FFF"}}/></IconButton> */}
                        <Box component="img" sx={{width:"32px", height:"32px"}} src={TransLogo}></Box>
                        <Typography sx={{ fontSize: "14px", fontWeight: 600, lineHeight: "28px", color: "black" }}>KOPERASI SERBAGUNA KEBANGSAAN BERHAD (4391)</Typography>
                    </Box>

                    <Box sx={{ display: "flex", alignItems: "center",justifyContent:"space-between", gap: "16px" }}>
                        {/* {!pathsWithNavBar.includes(location.pathname) &&<> 
                            <Button component={Link} to="/account-statement" variant="filled" sx={{ background: "#fff", borderColor: "##fff", color: "#288d2c", "&:hover": { borderColor: "#fff", background: "#fff" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", }} startIcon={<AccountBalanceIcon />} >Account Statement</Button>
                            </>} */}

                        <Tooltip title="Logout" arrow>
                            <Button
                                sx={{ color: "black" }}
                                onClick={handleLogout}
                            >
                                <Logout />
                            </Button>
                        </Tooltip>
                    </Box>
                </Box>
            </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
            <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
                <Mui_Tooltip open={open} title="Member Details">
                <ListItemButton sx={{display: "flex",alignItems:"center",gap:"16px",padding:"16px",width:"100%",background: active_tab=="MD" ? '#288d2c' : 'transparent','&:hover': { background: active_tab=="MD" ? '#288d2c' : '#288d2c',color: active_tab=="MD" ? '#FFFFFF' : '#FFFFFF'},color: active_tab=="MD" ? '#FFFFFF' : '#64748B', fontWeight:active_tab=="MD"?600:400}} onClick={() => handleTabChange("MD")}>
                    <Box>
                    <GroupsOutlinedIcon sx={{fontSize:"24px",fontWeight:active_tab=="MD"?600:400}}/>
                    </Box>
                    <ListItemText primary="Member Details" sx={{fontFamily:"inter",fontSize:"16px",lineHeight:"28px",fontWeight:active_tab=="MD"?600:400}} />
                </ListItemButton>
                </Mui_Tooltip>   

                {/* <Mui_Tooltip open={open} title="Actions and Receipts">
                <ListItemButton sx={{display: "flex",alignItems:"center",gap:"16px",padding:"16px",width:"100%",background: openAR ? '#288d2c' : 'transparent','&:hover': { background: openAR ? '#288d2c' : '#288d2c',color: openAR ? '#FFFFFF' : '#FFFFFF'},color: openAR ? '#FFFFFF' : '#64748B',fontWeight:openAR==true?600:400}} onClick={() => handleClick("AR")}>
                    <Box>
                    <MonetizationOnOutlinedIcon sx={{fontSize:"24px",fontWeight:openAR==true?600:400}}/>
                    </Box>
                    <ListItemText primary="Actions & Receipts" sx={{fontFamily:"inter",fontSize:"16px",lineHeight:"28px",fontWeight:openAR==true?600:400}} />
                    {openAR==true ? <ExpandMore /> : <ExpandMore sx={{ transform: "rotate(-90deg)"}}/>}
                </ListItemButton>
                </Mui_Tooltip>  */}

                  <Mui_Tooltip open={open} title="Receipts">
                      <ListItemButton sx={{display: "flex",alignItems:"center",gap:"16px",padding:"16px",width:"100%",background: active_tab=="ARR" ? '#288d2c' : 'transparent','&:hover': { background: active_tab=="ARR" ? '#288d2c' : '#288d2c',color: active_tab=="ARR" ? '#FFFFFF' : '#FFFFFF'},color: active_tab=="ARR" ? '#FFFFFF' : '#64748B', fontWeight:active_tab=="ARR"?600:400}} onClick={() => { handleTabChange('ARR') }}>
                          <Box>
                              <ReceiptOutlinedIcon sx={{fontSize:"24px",fontWeight:active_tab=="ARR"?600:400}} />
                          </Box>
                          <ListItemText primary="Receipts" sx={{fontFamily:"inter",fontSize:"16px",lineHeight:"28px",fontWeight:active_tab=="ARR"?600:400}}/>
                      </ListItemButton>
                  </Mui_Tooltip> 

                <Mui_Tooltip open={open} title="Payments">
                    <ListItemButton sx={{display: "flex",alignItems:"center",gap:"16px",padding:"16px",width:"100%",background: active_tab=="ARP" ? '#288d2c' : 'transparent','&:hover': { background: active_tab=="ARP" ? '#288d2c' : '#288d2c',color: active_tab=="ARP" ? '#FFFFFF' : '#FFFFFF'},color: active_tab=="ARP" ? '#FFFFFF' : '#64748B', fontWeight:active_tab=="ARP"?600:400}} onClick={() => { handleTabChange('ARP') }}>
                        <Box>
                            <PaymentsOutlinedIcon sx={{fontSize:"24px",fontWeight:active_tab=="ARP"?600:400}} />
                        </Box>
                        <ListItemText primary="Payments" sx={{fontFamily:"inter",fontSize:"16px",lineHeight:"28px",fontWeight:active_tab=="ARP"?600:400}}/>
                    </ListItemButton>
                </Mui_Tooltip> 

                <Mui_Tooltip open={open} title="Loan">
                <ListItemButton sx={{display: "flex",alignItems:"center",gap:"16px",padding:"16px",width:"100%",background: active_tab=="ARL" ? '#288d2c' : 'transparent','&:hover': { background: active_tab=="ARL" ? '#288d2c' : '#288d2c',color: active_tab=="ARL" ? '#FFFFFF' : '#FFFFFF'},color: active_tab=="ARL" ? '#FFFFFF' : '#64748B', fontWeight:active_tab=="ARL"?600:400}} onClick={()=>{handleTabChange('ARL')}}>
                    <Box>
                    <ReceiptLongOutlinedIcon sx={{fontSize:"24px",fontWeight:active_tab=="ARL"?600:400}}/>
                    </Box>
                    <ListItemText primary="Loan" sx={{fontFamily:"inter",fontSize:"16px",lineHeight:"28px",fontWeight:active_tab=="ARL"?600:400}}/>
                </ListItemButton>
                </Mui_Tooltip> 

                <Mui_Tooltip open={open} title="Specific Deposit">
                <ListItemButton sx={{display: "flex",alignItems:"center",gap:"16px",padding:"16px",width:"100%",background: active_tab=="ARSD" ? '#288d2c' : 'transparent','&:hover': { background: active_tab=="ARSD" ? '#288d2c' : '#288d2c',color: active_tab=="ARSD" ? '#FFFFFF' : '#FFFFFF'},color: active_tab=="ARSD" ? '#FFFFFF' : '#64748B', fontWeight:active_tab=="ARSD"?600:400}}onClick={()=>{handleTabChange('ARSD')}}>
                    <Box>
                    <SavingsOutlinedIcon sx={{fontSize:"24px",fontWeight:active_tab=="ARSD"?600:400}}/>
                    </Box>
                    <ListItemText primary="Specific Deposit" sx={{fontFamily:"inter",fontSize:"16px",lineHeight:"28px",fontWeight:active_tab=="ARSD"?600:400}}/>
                </ListItemButton>
                </Mui_Tooltip>            

                <Mui_Tooltip open={open} title="Dividend">
                <ListItemButton sx={{display: "flex",alignItems:"center",gap:"16px",padding:"16px",width:"100%",background: active_tab=="ARD" ? '#288d2c' : 'transparent','&:hover': { background: active_tab=="ARD" ? '#288d2c' : '#288d2c',color: active_tab=="ARD" ? '#FFFFFF' : '#FFFFFF'},color: active_tab=="ARD" ? '#FFFFFF' : '#64748B', fontWeight:active_tab=="ARD"?600:400}} onClick={()=>{handleTabChange('ARD')}}>
                    <Box>
                        <StoreOutlinedIcon sx={{fontSize:"24px",fontWeight:active_tab=="ARD"?600:400}}/>
                    </Box>
                    <ListItemText primary="Dividend" sx={{fontFamily:"inter",fontSize:"16px",lineHeight:"28px",fontWeight:active_tab=="ARD"?600:400}}/>
                </ListItemButton>
                </Mui_Tooltip> 
                <Mui_Tooltip open={open} title="Deduction">
                <ListItemButton sx={{display: "flex",alignItems:"center",gap:"16px",padding:"16px",width:"100%",background: active_tab=="ARDE" ? '#288d2c' : 'transparent','&:hover': { background: active_tab=="ARDE" ? '#288d2c' : '#288d2c',color: active_tab=="ARDE" ? '#FFFFFF' : '#FFFFFF'},color: active_tab=="ARDE" ? '#FFFFFF' : '#64748B', fontWeight:active_tab=="ARDE"?600:400}} onClick={()=>{handleTabChange('ARDE')}}>
                    <Box>
                        <PriceChangeOutlinedIcon sx={{fontSize:"24px",fontWeight:active_tab=="ARDE"?600:400}}/>
                    </Box>
                    <ListItemText primary="Deduction" sx={{fontFamily:"inter",fontSize:"16px",lineHeight:"28px",fontWeight:active_tab=="ARDE"?600:400}}/>
                </ListItemButton>
                </Mui_Tooltip> 

                <Mui_Tooltip open={open} title="Share Certificate">
                <ListItemButton sx={{display: "flex",alignItems:"center",gap:"16px",padding:"16px",width:"100%",background: active_tab=="SC" ? '#288d2c' : 'transparent','&:hover': { background: active_tab=="SC" ? '#288d2c' : '#288d2c',color: active_tab=="SC" ? '#FFFFFF' : '#FFFFFF'},color: active_tab=="SC" ? '#FFFFFF' : '#64748B',fontWeight:active_tab=="SC"?600:400}} onClick={() => handleTabChange("SC")}>
                    <Box>
                    <ArticleIcon sx={{fontSize:"24px",fontWeight:active_tab=="SC"?600:400}}/>
                    </Box>
                    <ListItemText primary="Share Certificate" style={{fontFamily:"inter",fontSize:"16px",lineHeight:"28px",fontWeight:active_tab=="SC"?600:400}} />
                </ListItemButton>
                </Mui_Tooltip>

                {/* <Mui_Tooltip open={open} title="Account Statement">
                <ListItemButton sx={{display: "flex",alignItems:"center",gap:"16px",padding:"16px",width:"100%",background: active_tab=="AS" ? '#288d2c' : 'transparent','&:hover': { background: active_tab=="AS" ? '#288d2c' : '#288d2c',color: active_tab=="AS" ? '#FFFFFF' : '#FFFFFF'},color: active_tab=="AS" ? '#FFFFFF' : '#64748B',fontWeight:active_tab=="AS"?600:400}} onClick={() => handleTabChange("AS")}>
                    <Box>
                    <AccountBalanceIcon sx={{fontSize:"24px",fontWeight:active_tab=="AS"?600:400}}/>
                    </Box>
                    <ListItemText primary="Account Statement" style={{fontFamily:"inter",fontSize:"16px",lineHeight:"28px",fontWeight:active_tab=="AS"?600:400}} />
                </ListItemButton>
                </Mui_Tooltip> */}

                <Mui_Tooltip open={open} title="Account Statement">
                <ListItemButton sx={{display: "flex",alignItems:"center",gap:"16px",padding:"16px",width:"100%",background: active_tab=="BAS" ? '#288d2c' : 'transparent','&:hover': { background: active_tab=="BAS" ? '#288d2c' : '#288d2c',color: active_tab=="BAS" ? '#FFFFFF' : '#FFFFFF'},color: active_tab=="BAS" ? '#FFFFFF' : '#64748B',fontWeight:active_tab=="BAS"?600:400}} onClick={() => handleTabChange("BAS")}>
                    <Box>
                    <AccountBalanceIcon sx={{fontSize:"24px",fontWeight:active_tab=="BAS"?600:400}}/>
                    </Box>
                    <ListItemText primary="Account Statement" style={{fontFamily:"inter",fontSize:"16px",lineHeight:"28px",fontWeight:active_tab=="BAS"?600:400}} />
                </ListItemButton>
                </Mui_Tooltip>

                <Mui_Tooltip open={open} title="AI Reports">
                <ListItemButton disabled={true} style={{display: "flex",alignItems:"center",gap:"16px",padding:"16px",width:"280px",background: active_tab=="AIR" ? '#288d2c' : 'transparent','&:hover': { background: active_tab=="AIR" ? '#288d2c' : '#288d2c',color: active_tab=="AIR" ? '#FFFFFF' : '#FFFFFF'},color: active_tab=="AIR" ? '#FFFFFF' : '#64748B',fontWeight:active_tab=="AIR"?600:400}} onClick={()=>{handleTabChange('AIR')}}>
                    <Box>
                    <InsightsIcon sx={{fontSize:"24px",color:active_tab=="AIR"?"#FFFFFF":"#64748B",fontWeight:active_tab=="AIR"?600:400}}/>
                    </Box>
                    <ListItemText primary="AI Reports" style={{fontFamily:"inter",fontSize:"16px",lineHeight:"28px",color:active_tab=="AIR"?"#FFFFFF":"#64748B",fontWeight:active_tab=="AIR"?600:400}}/>
                </ListItemButton>
                </Mui_Tooltip>

                <Mui_Tooltip open={open} title="Master Records">
                    <ListItemButton
                        onClick={() => handleClick("MR")}
                        sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '16px',
                        padding: '16px',
                        width: '100%',
                        background: openMR ? '#288d2c' : 'transparent',
                        '&:hover': {
                            background: openMR ? '#288d2c' : '#288d2c',
                            color: openMR ? '#FFFFFF' : '#FFFFFF'
                        },
                        color: openMR ? '#FFFFFF' : '#64748B',
                        fontWeight: openMR ? 600 : 400,
                        fontSize:'32px',
                        fontFamily:"inter",
                        }}
                    >
                        <AutoStoriesOutlinedIcon sx={{ fontSize: '24px', fontWeight: openMR ? 600 : 400 }} />
                        <ListItemText primary="Master Records" sx={{ fontFamily: 'inter', fontSize: '16px', lineHeight: '28px', fontWeight: openMR ? 600 : 400 }} />
                        {openMR ? <ExpandMore /> : <ExpandMore sx={{ transform: 'rotate(-90deg)' }} />}
                    </ListItemButton>
                </Mui_Tooltip>                
                <Collapse in={openMR} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                    <Mui_Tooltip open={open} title="Estate">
                        <ListItemButton style={active_tab=="MRE"?{background:"#E2E8F0",padding:"16px",display:"flex",gap:"16px"} :{cursor:"pointer","&:hover":{background:"red"},padding:"16px",display:"flex",gap:"16px"}} onClick={()=>{handleTabChange('MRE', true)}}>
                            <Box>
                            <LocationCityOutlinedIcon sx={{fontSize:"24px",fontWeight:active_tab=="MRE"?600:400 ,color:active_tab=="MRE"?"#288d2c":"#64748B"}}/>
                            </Box>
                            <Typography sx={{fontFamily:"inter",fontSize:"16px",fontWeight:active_tab=="MRE"?700:400,lineHeight:"28px",color:active_tab=="MRE"?"#101010":"#64748B"}}>Estate</Typography>
                        </ListItemButton>
                        </Mui_Tooltip>

                        <Mui_Tooltip open={open} title="Bank Names">
                        <ListItemButton style={active_tab=="MRB"?{background:"#E2E8F0",padding:"16px",display:"flex",gap:"16px"} :{cursor:"pointer","&:hover":{background:"#E2E8F0"},padding:"16px",display:"flex",gap:"16px"}} onClick={()=>{handleTabChange('MRB', true)}}>
                            <Box>
                            <AccountBalanceOutlinedIcon sx={{fontSize:"24px",fontWeight:active_tab=="MRB"?600:400 ,color:active_tab=="MRB"?"#288d2c":"#64748B"}}/>
                            </Box>
                            <Typography sx={{fontFamily:"inter",fontSize:"16px",fontWeight:active_tab=="MRB"?700:400,lineHeight:"28px",color:active_tab=="MRB"?"#101010":"#64748B"}}>Bank Names</Typography>
                        </ListItemButton>
                        </Mui_Tooltip>

                        <Mui_Tooltip open={open} title="Co-Op Bank Name">
                        <ListItemButton style={active_tab=="MRCB"?{background:"#E2E8F0",padding:"16px",display:"flex",gap:"16px"} :{cursor:"pointer","&:hover":{background:"#E2E8F0"},padding:"16px",display:"flex",gap:"16px"}} onClick={()=>{handleTabChange('MRCB', true)}}>
                            <Box>
                            <AccountBalanceSharpIcon sx={{fontSize:"24px",fontWeight:active_tab=="MRCB"?600:400 ,color:active_tab=="MRCB"?"#288d2c":"#64748B"}}/>
                            </Box>
                            <Typography sx={{fontFamily:"inter",fontSize:"16px",fontWeight:active_tab=="MRCB"?700:400,lineHeight:"28px",color:active_tab=="ARSD"?"#101010":"#64748B"}}>Co-Op Bank Name</Typography>
                        </ListItemButton>
                        </Mui_Tooltip>

                        <Mui_Tooltip open={open} title="Occupation">
                        <ListItemButton style={active_tab=="MROcc"?{background:"#E2E8F0",padding:"16px",display:"flex",gap:"16px"} :{cursor:"pointer","&:hover":{background:"#E2E8F0"},padding:"16px",display:"flex",gap:"16px"}} onClick={()=>{handleTabChange('MROcc', true)}}>
                            <Box>
                            <WorkSharpIcon sx={{fontSize:"24px",fontWeight:active_tab=="MROcc"?600:400 ,color:active_tab=="MROcc"?"#288d2c":"#64748B"}}/>
                            </Box>
                            <Typography sx={{fontFamily:"inter",fontSize:"16px",fontWeight:active_tab=="MROcc"?700:400,lineHeight:"28px",color:active_tab=="ARSD"?"#101010":"#64748B"}}>Occupation</Typography>
                        </ListItemButton>
                        </Mui_Tooltip>
                    </List>
                </Collapse>

                <Mui_Tooltip open={open} title="Executive Details">
                <ListItemButton sx={{display: "flex",alignItems:"center",gap:"16px",padding:"16px",width:"100%",background: openES ? '#288d2c' : 'transparent','&:hover': { background: openES ? '#288d2c' : '#288d2c',color: openES ? '#FFFFFF' : '#FFFFFF'},color: openES ? '#FFFFFF' : '#64748B',fontWeight:openES==true?600:400}} onClick={() => handleClick("ES")}>
                    <Box>
                    <SummarizeOutlinedIcon sx={{fontSize:"24px",fontWeight:openES==true?600:400}}/>
                    </Box>
                    <ListItemText primary="Executive Details" style={{fontFamily:"inter",fontSize:"16px",lineHeight:"28px",fontWeight:openES==true?600:400}}  />
                    {openES ? <ExpandMore /> : <ExpandMore style={{ transform: "rotate(-90deg)"}}/>}
                </ListItemButton>
                </Mui_Tooltip> 
                <Collapse in={openES} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                         <Mui_Tooltip open={open} title="Cut-off Date">
                        <ListItemButton style={active_tab=="CO"?{background:"#E2E8F0",padding:"16x",display:"flex",gap:"16px"} :{cursor:"pointer","&:hover":{background:"#E2E8F0"},padding:"16px",display:"flex",gap:"16px"}} onClick={()=>{handleTabChange('CO', true)}}>
                            <Box>
                            <DateRangeOutlinedIcon sx={{fontSize:"24px",fontWeight:active_tab=="CO"?600:400 ,color:active_tab=="CO"?"#288d2c":"#64748B"}}/>
                            </Box>
                            <Typography sx={{fontFamily:"inter",fontSize:"16px",fontWeight:active_tab=="CO"?700:400,lineHeight:"28px",color:active_tab=="CO"?"#101010":"#64748B"}}>Cut-off date</Typography>
                        </ListItemButton>
                        </Mui_Tooltip> 

                        <Mui_Tooltip open={open} title="Receipt Issued">
                        <ListItemButton style={active_tab=="RI"?{background:"#E2E8F0",padding:"16x",display:"flex",gap:"16px"} :{cursor:"pointer","&:hover":{background:"#E2E8F0"},padding:"16px",display:"flex",gap:"16px"}} onClick={()=>{handleTabChange('RI', true)}}>
                            <Box>
                            <ReceiptLongOutlinedIcon sx={{fontSize:"24px",fontWeight:active_tab=="RI"?600:400 ,color:active_tab=="RI"?"#288d2c":"#64748B"}}/>
                            </Box>
                            <Typography sx={{fontFamily:"inter",fontSize:"16px",fontWeight:active_tab=="RI"?700:400,lineHeight:"28px",color:active_tab=="RI"?"#101010":"#64748B"}}>Receipt Issued</Typography>
                        </ListItemButton>
                        </Mui_Tooltip> 

                        <Mui_Tooltip open={open} title="New Member Report">
                        <ListItemButton style={active_tab=="NMC"?{background:"#E2E8F0",padding:"16x",display:"flex",gap:"16px"} :{cursor:"pointer","&:hover":{background:"#E2E8F0"},padding:"16px",display:"flex",gap:"16px"}} onClick={()=>{handleTabChange('NMC', true)}}>
                            <Box>
                            <PersonAddAltOutlinedIcon sx={{fontSize:"24px",fontWeight:active_tab=="NMC"?600:400 ,color:active_tab=="NMC"?"#288d2c":"#64748B"}}/>
                            </Box>
                            <Typography sx={{fontFamily:"inter",fontSize:"16px",fontWeight:active_tab=="NMC"?700:400,lineHeight:"28px",color:active_tab=="NMC"?"#101010":"#64748B"}}>New Member Report</Typography>
                        </ListItemButton>
                        </Mui_Tooltip> 

                        <Mui_Tooltip open={open} title="Withdrawal">
                        <ListItemButton style={active_tab=="WD"?{background:"#E2E8F0",padding:"16x",display:"flex",gap:"16px"} :{cursor:"pointer","&:hover":{background:"#E2E8F0"},padding:"16px",display:"flex",gap:"16px"}} onClick={()=>{handleTabChange('WD', true)}}>
                            <Box>
                                <MoneyOutlinedIcon sx={{fontSize:"24px",fontWeight:active_tab=="WD"?600:400 ,color:active_tab=="WD"?"#288d2c":"#64748B"}}/>
                            </Box>
                            <Typography sx={{fontFamily:"inter",fontSize:"16px",fontWeight:active_tab=="WD"?700:400,lineHeight:"28px",color:active_tab=="WD"?"#101010":"#64748B"}}>Withdrawal</Typography>
                        </ListItemButton>
                        </Mui_Tooltip> 

                        <Mui_Tooltip open={open} title="Ageing Member">
                        <ListItemButton style={active_tab=="AM"?{background:"#E2E8F0",padding:"16x",display:"flex",gap:"16px"} :{cursor:"pointer","&:hover":{background:"#E2E8F0"},padding:"16px",display:"flex",gap:"16px"}} onClick={()=>{handleTabChange('AM', true)}}>
                            <Box>
                                <ElderlyOutlinedIcon sx={{fontSize:"24px",fontWeight:active_tab=="AM"?600:400 ,color:active_tab=="AM"?"#288d2c":"#64748B"}}/>
                            </Box>
                            <Typography sx={{fontFamily:"inter",fontSize:"16px",fontWeight:active_tab=="AM"?700:400,lineHeight:"28px",color:active_tab=="AM"?"#101010":"#64748B"}}>Ageing Member</Typography>
                        </ListItemButton>
                        </Mui_Tooltip>

                        <Mui_Tooltip open={open} title="Ageing Estate">
                        <ListItemButton style={active_tab=="AE"?{background:"#E2E8F0",padding:"16x",display:"flex",gap:"16px"} :{cursor:"pointer","&:hover":{background:"#E2E8F0"},padding:"16px",display:"flex",gap:"16px"}} onClick={()=>{handleTabChange('AE', true)}} disabled={true}>
                            <Box>
                                <Person4OutlinedIcon sx={{fontSize:"24px",fontWeight:active_tab=="AE"?600:400 ,color:active_tab=="AE"?"#288d2c":"#64748B"}} />
                            </Box>
                            <Typography sx={{fontFamily:"inter",fontSize:"16px",fontWeight:active_tab=="AE"?700:400,lineHeight:"28px",color:active_tab=="AE"?"#101010":"#64748B"}}>Ageing Estate</Typography>
                        </ListItemButton>
                        </Mui_Tooltip>

                        <Mui_Tooltip open={open} title="Nominee Status">
                        <ListItemButton style={active_tab=="NS"?{background:"#E2E8F0",padding:"16x",display:"flex",gap:"16px"} :{cursor:"pointer","&:hover":{background:"#E2E8F0"},padding:"16px",display:"flex",gap:"16px"}} onClick={()=>{handleTabChange('NS', true)}}>
                            <Box>
                                <PeopleOutlineOutlinedIcon sx={{fontSize:"24px",fontWeight:active_tab=="NS"?600:400 ,color:active_tab=="NS"?"#288d2c":"#64748B"}}/>
                            </Box>
                            <Typography sx={{fontFamily:"inter",fontSize:"16px",fontWeight:active_tab=="NS"?700:400,lineHeight:"28px",color:active_tab=="NS"?"#101010":"#64748B"}}>Nominee Status</Typography>
                        </ListItemButton>
                        </Mui_Tooltip>
                    </List>
                </Collapse>

            </List>
        </Drawer>
    </Box>
  );
}