import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from "@mui/material";

import CustomAxios from "../utils/CustomAxios";

export default function AddOccupation({
  open,
  onClose,
  onOccupationAdded, // Callback to parent when an occupation is successfully added
}) {
  const [newOccupation, setNewOccupation] = useState("");

  const handleAddOccupation = async () => {
    try {
      // API call to add the new occupation
      const response = await CustomAxios.post("oasis/add-occupation/", { name: newOccupation });
        // console.log("response")
      // Notify the parent component about the new occupation
      onOccupationAdded(response.data);

      // Reset the input and close the modal
      setNewOccupation("");
      onClose();
    } catch (error) {
      console.error("Error adding occupation:", error);
    //   alert("Failed to add the occupation. Please try again.");
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add New Occupation</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="New Occupation"
          fullWidth
          value={newOccupation}
          onChange={(e) => setNewOccupation(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleAddOccupation} disabled={!newOccupation.trim()}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
